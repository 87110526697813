#main {
    background: linear-gradient(rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)),
        url("./assets/Container.png");
    background-blend-mode: darken;
}


* {
    font-family: Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif;
}

body {
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 1);
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif;
}

.min-hbody-full {
    height: calc(100vh - 160px);
}

button:disabled,
input:disabled {
    opacity: .5;
    cursor: not-allowed;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.bg-gradient {
    background: linear-gradient(to right, #383737, #6a6a6a, #3d3434);
} 

.vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode){
  padding: 0;
}

.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 0;
}

.video-js .vjs-control-bar {
  background: none !important; 
}

.video-js.vjs-logo .vjs-logo-content.vjs-logo-hide {
  visibility: visible !important;
  opacity: 0.5 !important;
}

/* @media (max-width: 480px) { 
  .video-js.vjs-logo .vjs-logo-content.vjs-logo-hide {
    width: 15% !important; 
    height: auto;
  } */
  /* .video-js.vjs-fluid:not(.vjs-audio-only-mode),
  .video-js.vjs-16-9:not(.vjs-audio-only-mode),
  .video-js.vjs-4-3:not(.vjs-audio-only-mode),
  .video-js.vjs-9-16:not(.vjs-audio-only-mode),
  .video-js.vjs-1-1:not(.vjs-audio-only-mode) {
    height: 32vh !important; 
  } */
/* } */

/* .video-js.vjs-fluid:not(.vjs-audio-only-mode), 
.video-js.vjs-16-9:not(.vjs-audio-only-mode), 
.video-js.vjs-4-3:not(.vjs-audio-only-mode), 
.video-js.vjs-9-16:not(.vjs-audio-only-mode), 
.video-js.vjs-1-1:not(.vjs-audio-only-mode) {
  height: 111vh !important; 
    max-height: 640px;
} */

#video-padding > .video-js{
  padding-top: 0px !important;
  height: 640px;
}

@media (max-width: 768px) {
  #video-padding > .video-js {
    height: 220px;
  }

  .video-js.vjs-logo .vjs-logo-content.vjs-logo-hide {
    width: 15% !important; 
    height: auto;
  }
}

.searchbox-container div.absolute {
  right: 0;
  min-width: 200px;
}

.text-pink-600 svg {
  color: red;
}

.custom-pink {
  background-color: #9d174d;
}

.no-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}